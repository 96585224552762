import { combineReducers } from 'redux'

import { profitAndLossReducer } from './profit_and_loss'
import expenses from './expenses'
import profitOverview from './profit_overview'
import profitOverviewV2 from './profit_overview_v2'

export default combineReducers({
  expenses,
  profitAndLoss: profitAndLossReducer,
  profitOverview,
  profitOverviewV2
})
